<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
  <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="showSnackBar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 lg11 pa-0 pa-sm-6>
        <v-card class="mx-auto elevation-2 pa-6" align="center" outlined>
          <v-layout wrap>
            <v-flex xs10 text-left>
              <span class="nsbold" style="font-size: 20px"> Admins </span>
            </v-flex>
            <v-flex xs2>
              <v-btn
                dark
                small
                color="#766BC0"
                class="rounded-lg"
                @click="adddialogue = true"
              >
                <v-icon x-small>mdi-plus</v-icon>
                <span
                  class="text-left nsregular"
                  style="font-weight: 400; font-size: 12px"
                  >Add Admin</span
                ></v-btn
              >
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 v-if="admins">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">No.</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Phone</th>
                      <th class="text-left">Role</th>
                      <th class="text-left">Edit</th>
                      <th class="text-left">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, i) in admins" :key="i">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td>{{ value.name }}</td>
                      <td>{{ value.phone }}</td>
                      <td>{{ value.role }}</td>
                      <td>
                        <v-icon
                          small
                          style="cursor: pointer"
                          @click="(curItem = value), (editdialogue = true)"
                          >mdi-circle-edit-outline</v-icon
                        >
                      </td>
                      <td>
                        <v-icon
                          small
                          style="cursor: pointer"
                          @click="(deletedialoge = true), (curId = value._id)"
                          >mdi-delete-outline</v-icon
                        >
                      </td>

                      <!-- <td>{{ value.studentid[0].admissionNo }}</td>
                                <td>{{ value.clothid.clothType.name }}</td>
                                <td>{{formatDate(value.INdate)}}</td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
          </v-layout>
        </v-card>
        <!---------------dialogue----------------->
        <!-- <v-dialog width="400px" v-model="dialoge">
            <v-card width="400px">
              <v-toolbar dark color="#766BC0" dense flat class="body-2">
                <v-toolbar-title> Confirm </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-4 black--text">
                <v-layout wrap>
                  <v-flex xs6> Admission No: {{ user.admissionNo }} </v-flex>
                  <v-flex xs6> Purpose: {{ user.purpose }} </v-flex>
                  <v-flex xs6> Name: {{ name }} </v-flex>
                  <v-flex xs6> From Date: {{ user.fromDate }} </v-flex>
                  <v-flex xs6> Class: {{ classs }} </v-flex>
                  <v-flex xs6> To: {{ user.toDate }} </v-flex>
                  <v-flex xs6> School: {{ school }} </v-flex>
                  <v-flex xs6> Destination: {{ user.destination }} </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="dialoge = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="add()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog> -->
        <!-- add -->
        <v-form ref="form1" v-model="valid">
          <v-dialog width="400px" v-model="adddialogue">
            <v-card width="400px">
              <v-toolbar dark color="#766BC0" dense flat class="body-2">
                <v-toolbar-title> Add Admin </v-toolbar-title>
              </v-toolbar>

              <v-layout wrap>
                <v-flex xs12 px-4 pt-4>
                  <v-text-field
                    autofocus
                    label="Name"
                    v-model="name"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 px-4 pt-4>
                  <v-text-field
                    autofocus
                    label="Phone"
                    v-model="phone"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 px-4 py-4>
                  <v-text-field
                    v-model="password"
                    dense
                    type="password"
                    label="Password"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 px-4 py-4>
                  <v-select
                    :items="roles"
                    v-model="role"
                    dense
                    label="Role"
                    outlined
                    hide-details
                  ></v-select>
                </v-flex>
                <!-- <v-flex xs12 px-4 py-4 v-if="role=='HostelWarden'">
              <v-select
                :items="HostelList"
                v-model="hostel"
                label="Hostel"
                item-text="hostelName"
                item-value="_id"
                clearable
                hide-details
                @input="getBlockid()"
                outlined
                dense
              ></v-select>
              
              </v-flex> -->
                <v-flex xs12 px-4 py-4 v-if="role == 'HostelWarden'">
                  <v-select
                    multiple
                    :items="BlockIds"
                    v-model="hostelblockid"
                    label="Select Block"
                    item-text="Block"
                    item-value="_id"
                    hide-details
                    outlined
                    dense
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="adddialogue = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="#766BC0"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="validateInput()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
        <v-form ref="form" v-model="valid1">
          <v-dialog width="400px" v-model="editdialogue">
            <v-card width="400px">
              <v-toolbar dark color="#766BC0" dense flat class="body-2">
                <v-toolbar-title> Edit Item </v-toolbar-title>
              </v-toolbar>
              <!-- {{ curItem}} -->
              <v-layout wrap v-if="curItem">
                <v-flex xs12 px-4 pt-4>
                  <v-text-field
                    v-model="curItem.name"
                    outlined
                    label="Name"
                    hide-details
                    dense
                    :rules="[(v) => !!v || 'This field is required']"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 px-4 py-4>
                  <v-text-field
                    v-model="curItem.phone"
                    outlined
                    :rules="[rules.min]"
                    label="Phone"
                    hide-details
                    dense
                  ></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 px-4 pb-4>
                  <v-text-field
                    v-model="curItem.role"
                    outlined
                    label="Role"
                    hide-details
                    dense
                  ></v-text-field>
                </v-flex> -->
                <!-- <v-flex xs12 px-4 pb-4>
                  <v-text-field
                    v-model="editpassword"
                    outlined
                    label="Password"
                    hide-details
                    dense
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    hint="At least 8 characters"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-flex> -->
                <v-flex xs12 px-4 py-4>
                   <v-text-field
                    v-model="curItem.role"
                    outlined
                    disabled
                    :rules="[rules.min]"
                    label="Role"
                    hide-details
                    dense
                  ></v-text-field>
                   <!-- <v-select
                    :items="roles"
                    v-model="curItem.role"
                    dense
                    label="Role"
                    outlined
                    hide-details
                  ></v-select> -->
                </v-flex>
                 <v-flex xs12 px-4 py-4 v-if="curItem.role == 'HostelWarden'">
                  <v-select
                    multiple
                    :items="BlockIds"
                    v-model="curItem.hostel"
                    label="Select Block"
                    item-text="Block"
                    item-value="_id"
                    hide-details
                    outlined
                    dense
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="editdialogue = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="editValidateInput()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
        <v-dialog width="400px" v-model="deletedialoge">
          <v-card width="400px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Confirm </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4"
              >Are you sure you want to delete this record?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="deletedialoge = false"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                class="body-2 font-weight-bold"
                outlined
                @click="deleteCategory()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <v-snackbar
          :timeout="1000"
          :value="true"
          right
          color="red"
          v-model="snackbar"
        >
          <span style="font-size: 20px"> {{ msg }}</span>
        </v-snackbar> -->
      </v-flex>
      <v-flex xs12 py-2>
        <v-pagination
          small
          color="#766BC0"
          total-visible="7"
          v-model="page"
          :length="Pagelength"
        >
        </v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showSnackBar: false,
      snackbar:false,
      valid: true,
      valid1: true,
      //delete
      curId: null,
      //edit
      curItem: null,
      editpassword: null,
      show1: false,
      ServerError: false,
      appLoading: false,
      adddialogue: false,
      editdialogue: false,
      deletedialoge: false,
      admins: [],
      HostelList: [],
      hostel: null,
      BlockIds: [],
      hostelblockid: [],
      name: null,
      phone: null,
      password: null,
      roles: [
        "laundryadmin",
        "foodstockadmin",
        "AssetAdmin",
        "SecurityAdmin",
        "HostelWarden",
        "rector",
      ],
      role: null,
      msg: null,
      keyword: null,
      page: 1,
      Pagelength: 0,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        min: (v) => v.length >= 10 || "Min 10 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
    // hostel(){
    //   this.getBlockid();
    // },
  },
  mounted() {
    this.getData();
    this.getBlockid();
  },
  methods: {
    testStep(window_data) {
      this.user.fromDate = window_data.mod;
      this.dateOnly = this.user.fromDate.slice(0, 10);
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/admin/getalllist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.admins = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.showSnackBar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getBlockid() {
      (this.hostelblockid = null), (this.appLoading = true);
      axios({
        method: "post",
        url: "/hostel/block/list",

        headers: {
          token: localStorage.getItem("token"),
        },
        // data:{
        //   hostelId:this.hostel
        // },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            if (response.data.data) {
              if (response.data.data.length > 0) {
                this.BlockIds = response.data.data;
                if (this.usertype1 == true) {
                  this.hostelblockid = response.data.hosteldata[0]._id;
                  this.getData();
                }
                //  this.hostelblockid = response.data.hosteldata[0]._id;
                //  this.getData();
              }
            }
            // this.Pagelength = Math.ceil(response.data.totalLength / 50);
          }
          //  else {
          //   this.appTimes();
          // }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    gethostel() {
      axios({
        method: "get",
        // url: "/hostel/name/getlist",
        url: "main/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
            //  this.getData();
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    validateInput() {
     
      if (!this.name) {
        
        this.msg = "Please provide name";
        this.showSnackBar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showSnackBar = true;
        return;
      } else if (!this.password) {
        this.msg = "Please password";
        this.showSnackBar = true;
        return;
      } 
      else if (!this.role) {
        this.msg = "Please Provide role";
        this.showSnackBar = true;
       
        return;
      }
      else if(this.role && this.role == "HostelWarden"){
        //  if (this.role == "HostelWarden") {
          if (!this.hostelblockid) {
            this.msg = "Please Select Block";
             this.showSnackBar = true;
            return;
          }
          else{
             this.addCategory();
          }
          return;
        // }
      }
       else {
        this.addCategory();
      }
    },
    editValidateInput() {
     
      if (!this.curItem.name) {
        
        this.msg = "Please provide name";
        this.showSnackBar = true;
        return;
      } else if (!this.curItem.phone) {
        this.msg = "Please provide phone";
        this.showSnackBar = true;
        return;
      }
      // else if (!this.curItem.role) {
      //   this.msg = "Please Provide role";
      //   this.showSnackBar = true;
       
      //   return;
      // }
      else if(this.curItem.role && this.curItem.role == "HostelWarden"){
        //  if (this.role == "HostelWarden") {
          if (!this.curItem.hostel) {
            this.msg = "Please BlockIds";
             this.showSnackBar = true;
            return;
          }
          else{
             this.editCategory();
          }
          return;
        // }
      }
       else {
        this.editCategory();
      }
    },
    addCategory() {
      // if (this.$refs.form1.validate()) {
      axios({
        url: "/add/admin",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          phone: this.phone,
          password: this.password,
          role: this.role,
          name: this.name,
          blockId: this.hostelblockid,
        },
      })
        .then((response) => {
          this.adddialogue = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          }
          (this.phone = null),
            (this.password = null),
            (this.role = null),
            this.$refs.form1.resetValidation();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
      // }
    },
    //edit button
    editCategory() {
      // if (this.$refs.form.validate()) {
        axios({
          url: "/admin/edit",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            id: this.curItem._id,
            name: this.curItem.name,
            phone: this.curItem.phone,
            // password: this.editpassword,
            blockIds: this.curItem.hostel,
          },
        })
          .then((response) => {
            this.editdialogue = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = "Updated Sucessfully";
              // this.$router.go(-1)
              this.showSnackBar = true;
              (this.editpassword = null), this.getData();
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      
    },
    deleteCategory() {
      axios({
        url: "/remove/admin",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.deletedialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
